import { UserService } from 'src/app/core/services/user/user.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { SpinnerService } from 'src/app/core/services/spinner/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AffiliateService } from 'src/app/core/services/affiliate/affiliate.service';
import { SEOService } from 'src/app/core/services/SEO/seoservice.service';
import { UserDataService } from 'src/app/core/services/user/userData.service';
import { catchError, concatMap, of, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getFase } from 'src/app/core/extensions/status.extensions';

@Component({
    selector: 'app-login-vtf',
    templateUrl: './login-vtf.component.html',
    styleUrls: ['./login-vtf.component.scss'],
    standalone: false
})
export class LoginVTFComponent implements OnInit {
  _userId: string;
  _userHash: string;
  public userType: string;
  public affiliatePath: string[];
  public creditList: any[];
  public activeCredit: any;
  public belenderActive: string = ''
  public isAppEnabled: boolean = false;
  public activeCreditList: any[]  
  constructor(
    private vtfService: AuthService,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private affiliateService: AffiliateService,
    private userDataService: UserDataService
    ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this._userId = params.get("id");
      this._userHash = params.get("hash");
      if(this.router.url.includes('belender')){
        this.belenderActive = '?belender=1'
      }
    })

    this.route.queryParams.subscribe((params) => {
      this.activeCredit = params['id']
    })
   
    this.vtfService.getToken(this._userId, this._userHash, this.belenderActive).subscribe(
      res => {
        this.setSession(res.token);
        this.userData();
      },
      err => {
      }
    )
  }

  public getIdFromPath(path: string): string {

    const segments = path?.split("/");
    return segments?.[segments.length - 1];
  }


  userData() {
    let userType;
    this.userDataService.setUserType('');
    this.userDataService.setNameSession('');
  
    this.userService.getUser(this._userId).pipe(
      concatMap((res) => {
        const userData = res;
        localStorage.setItem('userType', userData.tipo)
        this.userDataService.setUserType(userData.tipo)
        localStorage.setItem('userId', this._userId);
        localStorage.setItem('userName', userData.firstname)

        userType = localStorage.getItem('userType')
        if (userType === 'prestatario') {
          this.userDataService.setNameSession(userData.firstname);
          if (userData.afiliado) {
            localStorage.setItem('affiliateId', this.getIdFromPath(userData.afiliado));
            return this.affiliateService.getAffiliate(localStorage.getItem('affiliateId'))
          } else {
            this.router.navigate(['/prestatario/inactivo'])
            return of(null);
          }
        } else {
          localStorage.setItem('affiliateId', this.getIdFromPath(userData.afiliado));
          return of(null);
        }
      })
    ).subscribe((data) => {
      localStorage.setItem('financedRate', data.financedRate)
      localStorage.setItem('formacionAsnef', data.formacionAsnef)
      const businessId = this.getIdFromPath(data!.datosEmpresa)
      const linksEnabled = {
        inversores: data.usuarios,
        links: data.links,
        simulador: data.simulador,
        cobros: data.cobrosEnabled,
        reports: data.reports,
        usuarios: data.usuarios,
      }
      this.affiliateService.getAffiliateBasicInfo(localStorage.getItem('affiliateId')).pipe(
        tap((res) => {
          linksEnabled['transfers'] = res.transactionsEnabled;
          localStorage.setItem('linksEnabled', JSON.stringify(linksEnabled))
          const currentPath = environment.baseUrl
          this.userDataService.setLogoSession(currentPath +'/logos/'+ res.logo)
        })
      ).subscribe((fin) => {
        if (userType != 'prestatario') {
          this.userDataService.setNameSession(data.corporationName)
        }
        localStorage.setItem('businessId', this.getIdFromPath(data.datosEmpresa));
        localStorage.setItem('estadoCd', data.estadoCd);
        localStorage.setItem('corporationName', data.corporationName);
        localStorage.setItem('datosEmpresa', data.datosEmpresa);
        localStorage.setItem('associatedInvestor', this.getIdFromPath(data.associatedInvestor));
        localStorage.setItem('salesAgentId', this.getIdFromPath(data.zankSalesAgent));
  
        if (userType == 'prestatario') {
            this.router.navigate(['/prestatario'], { queryParams: { id: this.activeCredit} });
        } else {
          this.router.navigate(['/afiliado']);
        }
      })


    }, err => {
      /* console.log(err) */
    });
  }

  setSession(token: string) {
    if (token != '')
    this.vtfService.setSessionLogged(token);
  }

  logout() {
    return this.vtfService.logout();
  }

}
